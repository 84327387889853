import consumer from "./consumer";

consumer.subscriptions.create("TestChannel", {
  connected() {
    console.log("Connected to the test channel.");
  },
  disconnected() {
    console.log("Disconnected from the test channel.");
  },
  received(data) {
    console.log("Received data from the test channel:", data);
  }
});
